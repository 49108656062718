import {
  GetSuggestedTreatmentPlanResponse,
  FeatureResponse,
  SubscriptionDetailsResponse
} from "@9amhealth/openapi";
import React from "react";
import FunnelKey from "src/constants/funnelKey";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import {
  FUNNEL_QUESTIONNAIRE_ELIGIBILITY,
  FUNNEL_QUESTIONNAIRE_MEDICAL
} from "src/constants/misc";
import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import type {
  CustomQuestionnaireAnswer,
  CustomQuestionnaireFilterAnswerOptions
} from "src/state/QuestionnaireCubit/QuestionnaireState";
import ShipmentAddressCubit from "src/state/ShipmentAddressCubit/ShipmentAddressCubit";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import { PayerId } from "src/state/SubscriptionCubit/SubscriptionCubit";
import {
  authenticationState,
  subscriptionState,
  userPreferences,
  userState
} from "src/state/state";
import EstablishCareStep from "src/ui/components/SignupCustomContent/EstablishCareStep";
import HealthInformationExchangeStep from "src/ui/components/SignupCustomContent/HealthInformationExchangeStep";
import type {
  SignupCustomCampaign,
  SignupCustomCampaignName,
  SignupCustomStep
} from "src/ui/components/SignupCustomContent/SignupCustom.types";
import { SignupCustomStepType } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import SignupCustomIneligibility from "src/ui/components/SignupCustomContent/SignupCustomIneligibility";
import GenericCheckoutCustomizedCheckoutScreen from "src/ui/components/SignupCustomContent/campaigns/generic/GenericCheckoutCustomizedCheckoutScreen";
import CreatingProgramIntermediateStep from "src/ui/components/SignupCustomContent/campaigns/generic/GenericCreatingProgram";
import genericEnrollCheckoutSuccessScreenProps from "src/ui/components/SignupCustomContent/campaigns/generic/GenericEnrollCheckoutSuccessScreenProps";
import assessmentCompleteSuccessPageProps from "src/ui/components/SignupCustomContent/campaigns/generic/GenericFinalSuccessPageProps";
import { SignupCustomStepCheckoutRawContent } from "src/ui/components/SignupCustomContent/steps/SignupCustomStepCheckoutCustomRender";
import SignupCustomStepCheckoutDynamicContent from "../../SignupCustomStepCheckoutDynamicContent/SignupCustomStepCheckoutDynamicContent";
import Translate from "../../Translate/Translate";
import type { SignupSuccessPageProps } from "../SignupSuccessPage";

export enum GenericTemplateFunnelLocalStorageKeys {
  ConfirmedSuccessScreen = "9am_<funnel-name>-enrollment-success-seen",
  ProgramCreated = "9am_<funnel-name>-program-created-0-seen",
  SuggestionResponseEligible = "9am_<funnel-name>-suggestion-response-eligible-seen",
  CustomStepActionPrefix = "9am_<funnel-name>-custom-step-action-",
  evaluateFunnelType = "9am_<funnel-name>-evaluate-funnel-type",
  healthInformationExchange = "9am_<funnel-name>-health-information-exchange"
}

export function lsKey(funnelKey: FunnelKey, key: string) {
  return key.replace(
    "<funnel-name>",
    `${userState.state.userData?.id}-${funnelKey}`
  );
}

interface FactorySignupCustomCampaignOptions {
  funnelKey: FunnelKey;
  prettyName: string;
  name: SignupCustomCampaignName;
  logo?: string;
  customLogo?: string;
  logoSize?: SignupCustomCampaign["logoSize"];
  answerOverrides?: Record<string, CustomQuestionnaireAnswer[]>;
  filterAnswerOptions?: Record<
    string,
    CustomQuestionnaireFilterAnswerOptions[]
  >;
  stepOrder?: SignupCustomCampaignAvailableSteps[];
  getSubscriptionFromSuggestedTreatmentPlan?: boolean;
  personalisedFinalSuccessScreen?: boolean;
  availabilityFeatureFlag?: FeatureResponse.feature;
}

export enum SignupCustomCampaignAvailableSteps {
  eligibility = "eligibility",
  healthInformationExchange = "health-information-exchange",
  register = "register",
  shipping = "shipping",
  checkout = "checkout",
  medicalDetails = "medical-details",
  establishCare = "establish-care",
  finalSuccess = "success"
}

export type SubscriptionCoverageType = "cash-pay" | "payer-covered" | "unknown";

export default class FactorySignupCustomCampaign {
  private readonly campaign: SignupCustomCampaign;
  name: SignupCustomCampaignName;
  factoryOptions: FactorySignupCustomCampaignOptions;
  userIsEligible = false;
  answerOverrides: Record<string, CustomQuestionnaireAnswer[] | undefined>;
  filterAnswerOptions: Record<
    string,
    CustomQuestionnaireFilterAnswerOptions[] | undefined
  >;
  stepOrder: SignupCustomCampaignAvailableSteps[] = [
    SignupCustomCampaignAvailableSteps.eligibility,
    SignupCustomCampaignAvailableSteps.healthInformationExchange,
    SignupCustomCampaignAvailableSteps.register,
    SignupCustomCampaignAvailableSteps.shipping,
    SignupCustomCampaignAvailableSteps.medicalDetails,
    SignupCustomCampaignAvailableSteps.establishCare,
    SignupCustomCampaignAvailableSteps.checkout,
    SignupCustomCampaignAvailableSteps.finalSuccess
  ];

  log = (msg: string, ...add: unknown[]) => {
    if (featureFlags.getFlag(FeatureFlagName.verboseLogging)) {
      // eslint-disable-next-line no-console
      console.warn(`[FactorySignupCustomCampaign] ${msg}`, ...add);
    }
  };

  constructor(options: FactorySignupCustomCampaignOptions) {
    this.campaign = {
      funnelKey: options.funnelKey,
      prettyName: options.prettyName,
      name: options.name,
      logo: options.logo,
      logoSize: options.logoSize,
      customLogo: options.customLogo,
      availabilityFeatureFlag: options.availabilityFeatureFlag,
      steps: this.generateStepList
    };

    this.answerOverrides = options.answerOverrides ?? {};
    this.filterAnswerOptions = options.filterAnswerOptions ?? {};
    this.stepOrder = options.stepOrder ?? this.stepOrder;
    this.name = options.name;

    this.factoryOptions = options;

    this.populateDefaultSteps();
  }

  get isTcFunnel() {
    return this.campaign.funnelKey === FunnelKey.transcarent;
  }

  get(): SignupCustomCampaign {
    return this.campaign;
  }

  addedSteps: SignupCustomStep[] = [];
  generateStepList = (): SignupCustomStep[] => {
    this.refreshSteps();
    return this.addedSteps;
  };

  addStep(step: SignupCustomStep): typeof this {
    this.addedSteps.push(step);
    return this;
  }

  addStepsMap: Record<SignupCustomCampaignAvailableSteps, () => void> = {
    [SignupCustomCampaignAvailableSteps.eligibility]: () => {
      const initialEligibilityStep = this.createEligibilityStep({
        eligibilityQuestionnaireId: FUNNEL_QUESTIONNAIRE_ELIGIBILITY,
        questionnaireName: "Abbreviated Eligibility"
      });

      this.addStep(initialEligibilityStep).addStep(
        this.createIneligibilityStep({
          path: "initial-ineligible",
          dataCompleteCheck: this.completedInitialEligibilityCheck,
          resolveStep: initialEligibilityStep
        })
      );
    },
    [SignupCustomCampaignAvailableSteps.healthInformationExchange]: () => {
      this.addStep(
        this.createHealthInformationExchangeStep({
          name: translate("healthInfoExchange"),
          path: "health-information-exchange"
        })
      );
    },
    [SignupCustomCampaignAvailableSteps.register]: () => {
      this.addStep(this.createAccountStep());
      this.addStep(this.acceptConsentsStep());
    },
    [SignupCustomCampaignAvailableSteps.shipping]: () => {
      this.addStep(this.createShippingInfoStep());
    },
    [SignupCustomCampaignAvailableSteps.medicalDetails]: () => {
      const medicalDetailsStep = this.createMedicalDetailsStep({
        medicalQuestionnaireId: FUNNEL_QUESTIONNAIRE_MEDICAL,
        questionnaireName: "Medical Information",
        path: "medical-details",
        name: "Medical Details"
      });

      this.addStep(medicalDetailsStep).addStep(
        this.createIneligibilityStep({
          component: (
            <SignupCustomIneligibility
              hideButton={false}
              customButtonText={
                <Translate msg="ineligibility.medical.button" />
              }
              resolveStep={medicalDetailsStep}
            />
          ),
          resolveStep: medicalDetailsStep,
          path: "ineligible-medical-details",
          dataCompleteCheck: async () => {
            const checkDetails = await this.medicalDetailsEligibleCheck();
            return checkDetails;
          }
        })
      );
    },
    [SignupCustomCampaignAvailableSteps.checkout]: () => {
      this.addStep(this.createCreatingProgramInfoStep());
      this.addStep(this.createCheckoutStep());
    },
    [SignupCustomCampaignAvailableSteps.establishCare]: () => {
      this.addStep(
        this.createEstablishCareStep({
          name: "Establish Care",
          path: "establish-care"
        })
      );
    },
    [SignupCustomCampaignAvailableSteps.finalSuccess]: () => {
      this.addStep(this.createFinalSuccessStep());
    }
  };

  subscriptionCoverageType?: SubscriptionCoverageType;

  populateDefaultSteps(): typeof this {
    for (const step of this.stepOrder) {
      this.addStepsMap[step]();
    }

    return this;
  }

  refreshSteps = (): void => {
    this.addedSteps = [];
    this.populateDefaultSteps();
  };

  /**
   * Checkers
   */

  checkCovered = (): SubscriptionCoverageType => {
    const cashPaySubs = subscriptionState.filterAllSubscriptions({
      status: [
        SubscriptionDetailsResponse.status.DRAFT,
        SubscriptionDetailsResponse.status.ACTIVE
      ],
      metadataPartnerFunnel: [this.campaign.funnelKey],
      metadataPayerId: PayerId.CASH_PAY
    });

    const anyFunnelSub = subscriptionState.filterAllSubscriptions({
      status: [
        SubscriptionDetailsResponse.status.DRAFT,
        SubscriptionDetailsResponse.status.ACTIVE
      ],
      metadataPartnerFunnel: [this.campaign.funnelKey]
    });

    if (cashPaySubs.length > 0) {
      return "cash-pay";
    }

    if (anyFunnelSub.length > 0) {
      return "payer-covered";
    }

    return "unknown";
  };

  checkDraftOrActiveSubscription = (): boolean => {
    const subs = subscriptionState.filterAllSubscriptions({
      status: [
        SubscriptionDetailsResponse.status.DRAFT,
        SubscriptionDetailsResponse.status.ACTIVE
      ],
      metadataPartnerFunnel: [this.campaign.funnelKey]
    }) as SubscriptionDetailsResponse[] | undefined;

    return Boolean(subs?.length);
  };

  checkActiveSubscription = (): boolean => {
    return (
      subscriptionState.filterAllSubscriptions({
        status: [SubscriptionDetailsResponse.status.ACTIVE],
        metadataPartnerFunnel: [this.campaign.funnelKey]
      }).length > 0
    );
  };

  checkAnsweredQuestionnaire = async (
    questionnaireId: string
  ): Promise<boolean> => {
    return Boolean(
      await userState.checkUserHasAnsweredQuestionnaire(
        questionnaireId,
        {},
        false
      )
    );
  };

  hasShippingAddress = false;
  checkUserHasShippingAddress = async (): Promise<boolean> => {
    if (this.hasShippingAddress) {
      return true;
    }

    try {
      const userShippingAddressResponse =
        await ShipmentAddressCubit.loadShipmentAddress();
      const { street, zip, city, state } =
        userShippingAddressResponse.data.addressData;
      const userAddress = street && zip && city && state;
      if (userAddress) {
        this.hasShippingAddress = true;
        return true;
      }
    } catch (e) {
      reportErrorSentry(e);
    }

    return false;
  };

  loadUserSubscriptions = async (): Promise<void> => {
    return subscriptionState.loadAllSubscriptions();
  };

  updateSubscriptionsGetDraftOrActive = async (): Promise<boolean> => {
    await this.loadUserSubscriptions();
    return this.checkDraftOrActiveSubscription();
  };

  updateSubscriptionsGetActive = async (): Promise<boolean> => {
    await this.loadUserSubscriptions();
    return this.checkActiveSubscription();
  };

  updateAnsweredQuestionnaires = async (): Promise<void> => {
    await userState.loadUserAnsweredQuestionnaires();
  };

  completedInitialEligibilityCheck = async (): Promise<boolean> => {
    await this.loadUserSubscriptions();
    return this.checkDraftOrActiveSubscription();
  };

  checkMedicalDetailsQuestionnaireAnswered = async (): Promise<boolean> => {
    const answered = await this.checkAnsweredQuestionnaire(
      FUNNEL_QUESTIONNAIRE_MEDICAL
    );

    return answered;
  };

  medicalDetailsEligibleCheck = async (): Promise<boolean> => {
    const eligibility =
      await subscriptionState.getEligibilityForCurrentPartner();

    if (!eligibility) {
      return false;
    }

    return (
      eligibility.eligibility ===
      GetSuggestedTreatmentPlanResponse.eligibility.ELIGIBLE
    );
  };

  checkLocalStorageKey = (
    funnelKey: FunnelKey,
    localStorageKey: string
  ): boolean | undefined => {
    const checkKey = lsKey(funnelKey, localStorageKey);
    return StorageController.getItem(checkKey) === "true";
  };

  /**
   * Create Steps
   */

  createEligibilityStep(options: {
    eligibilityQuestionnaireId: string;
    questionnaireName: string;
  }): SignupCustomStep {
    return {
      name: "Eligibility",
      path: "eligibility",
      type: SignupCustomStepType.Questionnaire,
      questionnaireId: options.eligibilityQuestionnaireId,
      questionnaireName: options.questionnaireName,
      answerOverrides: this.answerOverrides[options.eligibilityQuestionnaireId],
      filterAnswerOptions:
        this.filterAnswerOptions[options.eligibilityQuestionnaireId],
      beforeNextStep: async () => {
        await Promise.all([
          this.updateAnsweredQuestionnaires(),
          this.updateSubscriptionsGetDraftOrActive()
        ]);
      },
      dataComplete: async (): Promise<boolean> => {
        const [answered] = await Promise.all([
          this.checkAnsweredQuestionnaire(options.eligibilityQuestionnaireId),
          // update user preferencrs in sync
          userPreferences.loadUserPreferences()
        ]);

        return answered;
      }
    };
  }

  createIneligibilityStep(options: {
    path: string;
    component?: React.ReactNode;
    dataCompleteCheck?: () => Promise<boolean>;
    resolveStep?: SignupCustomStep;
  }): SignupCustomStep {
    const hideRedirect =
      options.path === "initial-ineligible" && this.isTcFunnel;
    return {
      name: "Ineligible",
      path: options.path,
      type: SignupCustomStepType.InfoPage,
      background: "animate-primary",
      render: () =>
        options.component ?? (
          <SignupCustomIneligibility
            hideButton={hideRedirect}
            customContent={
              <>
                <h4 style={{ width: "80%", margin: "0 auto" }}>
                  <Translate msg="ineligibility.title" />
                </h4>
                <nine-spacer s="md"></nine-spacer>
                {!hideRedirect && (
                  <p className="m0 color-c-80">
                    <Translate msg="ineligibility.description" />
                  </p>
                )}
                <nine-spacer s="md"></nine-spacer>
              </>
            }
            customButtonText={<Translate msg="ineligibility.button" />}
            resolveStep={options.resolveStep}
          />
        ),
      dataComplete: async (): Promise<boolean> => {
        return options.dataCompleteCheck?.() ?? false;
      }
    };
  }

  createAccountStep(): SignupCustomStep {
    return {
      name: "Register",
      path: "register",
      type: SignupCustomStepType.Authentication,
      dataComplete: async () => {
        const fullAuthCheck = [
          userState.state.userData?.email,
          userState.state.userData?.passwordSet,
          authenticationState.state.authenticationStatus === "authenticated"
        ];
        const passwordlessUserCheck = [
          userState.state.userData?.email,
          authenticationState.state.authenticationStatus === "authenticated",
          subscriptionState.hasActiveSubscription
        ];
        return (
          this.isTcFunnel ||
          fullAuthCheck.every(Boolean) ||
          passwordlessUserCheck.every(Boolean)
        );
      }
    };
  }

  acceptConsentsStep(): SignupCustomStep {
    return {
      name: "Consents",
      path: "consents",
      type: SignupCustomStepType.Consents,
      dataComplete: async () => {
        return userState.hasConsentsNotAccepted === false;
      }
    };
  }

  createShippingInfoStep(): SignupCustomStep {
    return {
      name: "Shipping Address",
      path: "shipping",
      type: SignupCustomStepType.ShippingAddress,
      isRequired: true,
      dataComplete: this.checkUserHasShippingAddress,
      shippingFormPageProps: {
        title: translate("yourDetails"),
        subtitle: (
          <span
            style={{
              display: "block",
              maxWidth: `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`,
              margin: "0 auto"
            }}
          >
            <Translate msg="enterShippingInformation" />
          </span>
        )
      }
    };
  }

  get createdProgramCompleted(): boolean {
    const funnelKeyDefined = StorageController.getItem(
      lsKey(
        this.campaign.funnelKey,
        GenericTemplateFunnelLocalStorageKeys.ProgramCreated
      )
    );
    return Boolean(funnelKeyDefined);
  }

  set createdProgramCompleted(value: boolean) {
    StorageController.setItem(
      lsKey(
        this.campaign.funnelKey,
        GenericTemplateFunnelLocalStorageKeys.ProgramCreated
      ),
      String(value)
    );
  }

  createCreatingProgramInfoStep(): SignupCustomStep {
    return {
      name: "Creating Program",
      path: "creating-program",
      type: SignupCustomStepType.InfoPage,
      dataComplete: async () => {
        return Boolean(
          this.createdProgramCompleted ||
            subscriptionState.hasActiveSubscription
        );
      },
      beforeNextStep: async () => {
        this.createdProgramCompleted = true;
      },
      render: () => (
        <CreatingProgramIntermediateStep
          lsKey={lsKey(
            this.campaign.funnelKey,
            GenericTemplateFunnelLocalStorageKeys.ProgramCreated
          )}
        />
      )
    };
  }

  createCheckoutStep(): SignupCustomStep {
    return {
      name: "Review Checkout",
      path: "checkout",
      isRequired: true,
      type: SignupCustomStepType.InfoPage,
      background: "animate-primary",
      beforeNextStep: async () => {
        await this.updateSubscriptionsGetDraftOrActive();
      },
      dataComplete: async () => {
        return this.checkActiveSubscription();
      },
      render: () => {
        return (
          <SignupCustomStepCheckoutDynamicContent
            render={(dynamicProps) => (
              <SignupCustomStepCheckoutRawContent
                cartItems={dynamicProps.items}
                loading={false}
                content={{
                  programTitle: dynamicProps.program_title,
                  title: dynamicProps.title,
                  subtitle: dynamicProps.subtitle,
                  showMoneyBackGuarantee: false,
                  infoSections: () => (
                    <GenericCheckoutCustomizedCheckoutScreen
                      checkMetadataPartnerFunnel={this.campaign.funnelKey}
                      totalPrice={dynamicProps.totalPrice}
                      eligibleFoeMedicalBilling={
                        dynamicProps.eligibleForMedicalBilling
                      }
                    />
                  ),
                  legalAppendix: dynamicProps.legalAppendix,
                  cartSection: dynamicProps.cartSection
                }}
                showShipping={false}
                totalPrice={dynamicProps.totalPrice}
                priceAfterDiscount={dynamicProps.priceAfterDiscount}
                labTestsIncluded={false}
                paymentInterval={dynamicProps.paymentInterval}
                subscriptionId={dynamicProps.subscriptionId}
              />
            )}
          />
        );
      }
    };
  }

  createCheckoutSuccessStep(options: {
    dataCompleteCheck?: () => Promise<boolean>;
  }): SignupCustomStep {
    return {
      name: "Success - Next Steps",
      path: "checkout-success",
      type: SignupCustomStepType.SuccessPage,
      successPageProps: genericEnrollCheckoutSuccessScreenProps(),
      beforeNextStep: async () => {
        const key = lsKey(
          this.campaign.funnelKey,
          GenericTemplateFunnelLocalStorageKeys.ConfirmedSuccessScreen
        );

        StorageController.setItem(key, "true");
      },
      dataComplete: async (): Promise<boolean> => {
        if (options.dataCompleteCheck) {
          const optionCheck = await options.dataCompleteCheck();
          if (optionCheck) {
            return true;
          }
        }

        const funnelKeyDefined = this.checkLocalStorageKey(
          this.campaign.funnelKey,
          GenericTemplateFunnelLocalStorageKeys.ConfirmedSuccessScreen
        );

        if (funnelKeyDefined) {
          return true;
        }

        return false;
      }
    };
  }

  createMedicalDetailsStep(options: {
    medicalQuestionnaireId: string;
    questionnaireName: string;
    path: string;
    name: string;
  }): SignupCustomStep {
    return {
      name: options.name,
      path: options.path,
      isRequired: true,
      type: SignupCustomStepType.Questionnaire,
      questionnaireId: options.medicalQuestionnaireId,
      questionnaireName: options.questionnaireName,
      beforeNextStep: async () => {
        await Promise.all([
          this.updateAnsweredQuestionnaires(),
          this.loadUserSubscriptions()
        ]);
      },
      dataComplete: async () => {
        const [completedQuestionnaire] = await Promise.all([
          this.checkMedicalDetailsQuestionnaireAnswered()
        ]);
        return Boolean(completedQuestionnaire);
      }
    };
  }

  createEstablishCareStep(options: {
    dataCompleteCheck?: () => Promise<boolean>;
    name: string;
    path: string;
  }): SignupCustomStep {
    return {
      name: options.name,
      path: options.path,
      isRequired: true,
      type: SignupCustomStepType.InfoPage,
      dataComplete: async () => {
        if (options.dataCompleteCheck) {
          const optionCheck = await options.dataCompleteCheck();
          if (optionCheck) {
            return true;
          }
        }
        return userPreferences.isEstablishCareCompleted;
      },
      render: () => {
        return <EstablishCareStep />;
      }
    };
  }

  createHealthInformationExchangeStep(options: {
    dataCompleteCheck?: () => Promise<boolean>;
    name: string;
    path: string;
  }): SignupCustomStep {
    return {
      name: options.name,
      path: options.path,
      isRequired: false,
      type: SignupCustomStepType.InfoPage,
      dataComplete: async () => {
        return userPreferences.isHealthInformationExchangeCompleted;
      },
      render: () => {
        return <HealthInformationExchangeStep />;
      }
    };
  }

  createFinalSuccessStep(): SignupCustomStep {
    const successPageProps = {
      ...assessmentCompleteSuccessPageProps
    } satisfies SignupSuccessPageProps;

    return {
      name: "Success",
      path: "success",
      type: SignupCustomStepType.SuccessPage,
      successPageProps,
      dataComplete: async () => {
        return false;
      },
      action: (navigate) => {
        if (this.checkCovered() === "cash-pay") {
          navigate("/app");
        }
      }
    } satisfies SignupCustomStep;
  }
}
