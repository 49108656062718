import {
  AppointmentControllerService,
  AppointmentResponse,
  OpenAPI,
  UserShortLivedTokenControllerService
} from "@9amhealth/openapi";
import { isZoomLink } from "@9amhealth/shared";
import {
  getLocalTimeZone,
  now,
  parseAbsoluteToLocal
} from "@internationalized/date";
import { Cubit } from "blac-next";
import React from "react";
import SanityService from "src/api/SanityService";
import { globalEvents } from "src/constants/globalEvents";
import {
  DocumentCheckedIcon,
  IconCalendarPlus,
  IconClock,
  IconVideoRecorder
} from "src/constants/icons";
import { OpenBrowser } from "src/hybrid/components/Browser";
import { getSupportedUserLanguage } from "src/lib/i18next";
import reportErrorSentry from "src/lib/reportErrorSentry";
import sanityQuery from "src/lib/sanityQuery";
import { fileState, toast, tracker } from "src/state/state";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import type { TranslationKey } from "src/types/translationKey";
import {
  AppPopup,
  AppQueryPopupsController
} from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import { SupportedLabProvider } from "src/ui/components/AppQueryPopups/dialogs/LabcorpDialog";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import FilesCubit from "../FilesCubit/FilesCubit";
import { StorageController } from "../StorageBloc/StorageBloc";
import type = AppointmentResponse.type;
import status = AppointmentResponse.status;

export interface AppointmentsState {
  scheduledAppointments: AppointmentResponse[];
}

export interface AppointmentSanityDetails {
  eventName: string;
  joiningInstructions: string;
  language: string;
  _type: string;
  type: string;
  _rev: string;
  avatar?: Avatar;
  _createdAt: string;
  eventContent: EventContent[];
  _id: string;
  title: string;
  _updatedAt: string;
}

export interface Avatar {
  _type: string;
  assetId: string;
  url: string;
}

export interface EventContent {
  contentTitle: string;
  _key: string;
  content: Content[];
}

export interface Content {
  level?: number;
  _type: string;
  style: string;
  _key: string;
  listItem?: string;
  children: Children[];
}

export interface Children {
  _type: string;
  marks: string[];
  text: string;
  _key: string;
}

export interface ActionConfig {
  label: TranslationKey;
  icon: React.ReactNode;
  onClick: () => void;
}

export interface AppointmentConfig {
  cardActions: ActionConfig[];
  detailActions: ActionConfig[];
}

const DAY_IN_MINUTES = 24 * 60;

const HOUR_IN_MINUTES = 60;

class AppointmentsBloc extends Cubit<AppointmentsState> {
  appointments: AppointmentResponse[] = [];

  constructor() {
    super({
      scheduledAppointments: []
    });

    void this.getCachedAppointments();
    void this.loadAppointments();
  }

  getAppointmentMetaDataValue = (
    appointment: AppointmentResponse,
    key: "requisition_file_id"
  ) => {
    const additionalData = appointment.additionalData as
      | Record<string, unknown>
      | undefined;
    if (!additionalData || typeof additionalData !== "object") {
      return undefined;
    }

    return additionalData[key];
  };

  createAddToCalendarAction = (appointment: AppointmentResponse) => {
    return {
      label: "appointment.action.label.addToCalendar",
      icon: <IconCalendarPlus />,
      onClick: () => {
        tracker.track(TrackEvent.AppointmentAddToCalClick, {
          type: TrackType.click,
          data: {
            appointmentId: appointment.id
          }
        });

        BlockingLoadingOverlayController.startLoading();
        void AppointmentsBloc.downloadIcsFile(appointment.id).finally(() => {
          BlockingLoadingOverlayController.endLoading();
        });
      }
    } satisfies ActionConfig;
  };

  createJoinCallAction = (
    appointment: AppointmentResponse
  ): ActionConfig | null => {
    const visible = isZoomLink(appointment.location);
    if (!visible) {
      return null;
    }

    return {
      label: "appointment.action.label.joinCall",
      icon: <IconVideoRecorder />,
      onClick: () => {
        tracker.track(TrackEvent.AppointmentJoinCallClick, {
          type: TrackType.click,
          data: {
            location: appointment.location
          }
        });

        if (!appointment.location) {
          return;
        }

        window.open(appointment.location, "_blank");
      }
    } satisfies ActionConfig;
  };

  createRescheduleAction = (
    appointment: AppointmentResponse
  ): ActionConfig | null => {
    const visible = appointment.status === status.SCHEDULED;
    if (!visible) {
      return null;
    }
    const minutesToStart = this.minutesToStart(appointment);
    if (minutesToStart < HOUR_IN_MINUTES) {
      return null;
    }

    if (
      appointment.type === type.AT_HOME_LABS_GETLABS &&
      minutesToStart < DAY_IN_MINUTES
    ) {
      return null;
    }

    return {
      label: "appointment.action.label.reschedule",
      icon: <IconClock />,
      onClick: () => {
        tracker.track(TrackEvent.AppointmentRescheduleClick, {
          type: TrackType.click,
          data: {
            appointmentId: appointment.id
          }
        });

        BlockingLoadingOverlayController.startLoading();
        AppQueryPopupsController.closePopup();

        void AppointmentsBloc.getAppointmentReschedulingUrl(appointment.id)
          .then((url) => {
            AppQueryPopupsController.openPopup(AppPopup.rescheduleAppointment, {
              onEvent: {
                [globalEvents.APPOINTMENT_RESCHEDULED]: () => {
                  void this.loadAppointments();
                }
              },
              additionalParameters: {
                id: appointment.id,
                url,
                stay: "false"
              }
            });
          })
          .catch((e: unknown) => {
            reportErrorSentry(e);
            AppQueryPopupsController.openPopup(AppPopup.rescheduleAppointment, {
              onEvent: {
                [globalEvents.APPOINTMENT_RESCHEDULED]: () => {
                  void this.loadAppointments();
                }
              },
              additionalParameters: {
                id: appointment.id,
                stay: "false"
              }
            });
          })
          .finally(() => {
            BlockingLoadingOverlayController.endLoading();
          });
      }
    } satisfies ActionConfig;
  };

  createShowRequisitionFileAction = (appointment: AppointmentResponse) => {
    const requisitionFileId = this.getAppointmentMetaDataValue(
      appointment,
      "requisition_file_id"
    ) as string | undefined;
    if (!requisitionFileId) {
      return null;
    }
    return {
      label: "appointment.action.label.showRequisition",
      icon: <DocumentCheckedIcon />,
      onClick: () => {
        tracker.track(TrackEvent.AppointmentShowRequisitionClick, {
          type: TrackType.click,
          data: {
            appointmentId: appointment.id
          }
        });
        void FilesCubit.startFileDownload(requisitionFileId);
      }
    } satisfies ActionConfig;
  };

  createAppointmentConfig = (
    appointment: AppointmentResponse
  ): AppointmentConfig => {
    const actionCalendar = this.createAddToCalendarAction(appointment);
    const actionJoinCall = this.createJoinCallAction(appointment);
    const actionReschedule = this.createRescheduleAction(appointment);
    const actionRequisitionFile =
      this.createShowRequisitionFileAction(appointment);

    const cardActions = [
      actionCalendar,
      actionJoinCall,
      actionReschedule
    ].filter((action) => action !== null);

    const detailActions = [
      actionCalendar,
      actionJoinCall,
      actionRequisitionFile,
      actionReschedule
    ].filter((action) => action !== null);

    return { cardActions, detailActions };
  };

  private setAppointmentsConfig = (appointments: AppointmentResponse[]) => {
    return appointments.map((appointment) => {
      return {
        ...appointment,
        config: this.createAppointmentConfig(appointment)
      };
    });
  };

  cacheKey = "scheduled-appointments";
  public readonly getCachedAppointments = async () => {
    const cached = StorageController.getItemParsed<AppointmentResponse[]>(
      this.cacheKey
    );
    if (cached) {
      this.appointments = this.setAppointmentsConfig(cached);
      this.patch({
        scheduledAppointments: this.filterAppointments(this.appointments)
      });
    }
  };

  filterAppointments = (
    appointments: AppointmentResponse[],
    options: {
      /** Offset in minutes to consider an appointment expired, 15 = (now + 15 minutes) */
      expiredOffset?: number;
      /** If true, sort the appointments by start date */
      sort?: boolean;
      filter?: Partial<AppointmentResponse>;
    } = {}
  ) => {
    const {
      // Default to -15 minutes, which means the appointment is considered expired 15 minutes before the end date
      // expiredOffset = -15,
      expiredOffset = -15,
      filter = {
        status: status.SCHEDULED
      },
      sort = true
    } = options;
    const localTime = now(getLocalTimeZone());
    const offsetInMs = expiredOffset * 60000;

    let output = appointments;

    output = output.filter((appointment) => {
      const asZoned = parseAbsoluteToLocal(appointment.end);
      const isExpired = localTime.compare(asZoned) > offsetInMs;

      const filterMatch = Object.keys(filter).every((key) => {
        return (
          appointment[key as keyof AppointmentResponse] ===
          filter[key as keyof AppointmentResponse]
        );
      });

      return !isExpired && filterMatch;
    });

    if (sort) {
      output = output.toSorted((a, b) => {
        return parseAbsoluteToLocal(a.start).compare(
          parseAbsoluteToLocal(b.start)
        );
      });
    }

    return output;
  };

  public readonly loadAppointments = async () => {
    try {
      const { data } =
        await AppointmentControllerService.getScheduledAppointments();

      const appointments = this.setAppointmentsConfig(data);

      this.appointments = appointments;

      this.patch({
        scheduledAppointments: this.filterAppointments(this.appointments)
      });

      StorageController.setItem(
        this.cacheKey,
        JSON.stringify(this.appointments)
      );
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  minutesToStart = (appointment: AppointmentResponse): number => {
    const localTime = now(getLocalTimeZone());
    const startAbsolute = parseAbsoluteToLocal(appointment.start);
    const diffMs = startAbsolute.compare(localTime);
    const diffMinutes = Math.floor(diffMs / 1000 / 60);
    return diffMinutes;
  };

  static getAppointmentReschedulingUrl = async (appointmentId: string) => {
    const { data } =
      await AppointmentControllerService.initiateRescheduling(appointmentId);

    const { schedulingUrl } = data;

    return schedulingUrl;
  };

  static downloadIcsFile = async (appointmentId: string) => {
    try {
      const res =
        await UserShortLivedTokenControllerService.retrieveShortLivedToken();

      const { accessToken } = res.data;

      const fileUrl = `${OpenAPI.BASE}/v1/appointments/${appointmentId}.ics?authToken=${accessToken}`;

      void OpenBrowser(fileUrl, {
        presentationStyle: "popover",
        useBaseUrl: false
      });
    } catch (error) {
      toast.show("error_failed_appointment_calendar");

      reportErrorSentry(error);
    }
  };

  static getAppointmentCmsDetails = async (
    type: AppointmentResponse.type | SupportedLabProvider
  ) => {
    const language = getSupportedUserLanguage();

    const appointmentDetails: AppointmentSanityDetails[] =
      await SanityService.fetchSanityData(
        sanityQuery.appointmentDetailsByType(type, language)
      );

    return appointmentDetails[0];
  };

  public getAppointment = (id: string, appointments: AppointmentResponse[]) => {
    return appointments.find((appointment) => appointment.id === id);
  };
}

export default AppointmentsBloc;
