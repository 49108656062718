import type { CSSProperties } from "react";
import React from "react";
import translate from "src/lib/translate";
import { ProfileProgramMembership } from "src/state/UserCubit/UserCubit";
import type { CustomizedCheckoutScreenContentProps } from "src/ui/components/SignupCustomContent/state/CustomizedCheckoutScreenBloc";
import Translate from "src/ui/components/Translate/Translate";
import Highlights from "src/ui/styled/Highlights";
import { Container, List, ListElement, ListWrapper } from "src/ui/styled/List";
import NoteSection from "./NoteSection";

export const CheckoutContentDiabetesAndPrevention: CustomizedCheckoutScreenContentProps =
  {
    imageSrc:
      "https://cdn.sanity.io/images/g38rxyoc/production/eb94039611519d30538d1a2a8b61dc0e196c70e0-1374x768.jpg?w=800&auto=format",
    image: () => (
      <img
        src={
          "https://cdn.sanity.io/images/g38rxyoc/production/eb94039611519d30538d1a2a8b61dc0e196c70e0-1374x768.jpg?w=800&auto=format"
        }
        className="rounded-small aspect"
        style={{ "--img-aspect": "458/256" } as CSSProperties}
        alt={translate(
          `program.title_${ProfileProgramMembership.DIABETES_AND_HEART_DISEASE_PREVENTION}`
        )}
      ></img>
    ),
    title: () => (
      <Translate
        msg={`program.title_${ProfileProgramMembership.DIABETES_AND_HEART_DISEASE_PREVENTION}`}
      />
    ),
    subtitle: () => (
      <Translate
        msg={`program.description_${ProfileProgramMembership.DIABETES_AND_HEART_DISEASE_PREVENTION}`}
      />
    ),
    highlights: () => (
      <Highlights>
        <ul>
          <li>
            <img src="https://cdn.sanity.io/images/g38rxyoc/production/d7d49e4d77811f9ce2852641c170a47cde926dbf-20x20.svg" />
            <Translate msg="benefit.personalizedPlan" />
          </li>
          <li>
            <img src="https://cdn.sanity.io/images/g38rxyoc/production/bac9228b8960e98885b933a6b994ec6dee22de7e-20x20.svg" />
            <Translate msg="benefit.medicationOptimization" />
          </li>
          <li>
            <img src="https://cdn.sanity.io/images/g38rxyoc/production/e0f2240b106df7cf0b90806bb095236035476015-20x20.svg" />
            <Translate msg="benefit.onDemandCare" />
          </li>
          <li>
            <img src="https://cdn.sanity.io/images/g38rxyoc/production/1cad4c0a79c555e11ad2a0da8c6a3393f8245b02-20x20.svg" />
            <Translate msg="benefit.goalsProgressTracking" />
          </li>
        </ul>
      </Highlights>
    ),
    list: () => (
      <Container>
        <div style={{ position: "relative" }}>
          <ListWrapper>
            <h6 className="as-body2">
              <Translate msg="benefit.onboarding.title" />
            </h6>
            <List className="m0">
              <ListElement className="m0">
                <Translate msg="benefit.onlineHealthAssessment" />
              </ListElement>
              <ListElement>
                <Translate msg="benefit.metabolicLabTest" />
              </ListElement>
              <ListElement>
                <Translate msg="benefit.personalizedPlanDevelopment" />
              </ListElement>
              <ListElement>
                <Translate msg="benefit.careSpecialistVisit" />
              </ListElement>
              <ListElement>
                <Translate msg="benefit.prescriptions" />
              </ListElement>
              <ListElement>
                <Translate msg="benefit.insuranceNavigation" />
              </ListElement>
            </List>
          </ListWrapper>
        </div>
        <div style={{ position: "relative" }}>
          <ListWrapper>
            <h6 className="as-body2">
              <Translate msg="benefit.ongoing.title" />
            </h6>
            <List className="m0">
              <ListElement className="m0">
                <Translate msg="benefit.accessToSpecialists" />
              </ListElement>
              <ListElement>
                <Translate msg="benefit.ongoingCheckins" />
              </ListElement>
              <ListElement>
                <Translate msg="benefit.metabolicLabTest" />
              </ListElement>
              <ListElement>
                <Translate msg="benefit.prescriptionsManagement" />
              </ListElement>
              <ListElement>
                <Translate msg="benefit.behavioralLifestyleCoaching" />
              </ListElement>
              <ListElement>
                <Translate msg="benefit.connectedDevices" />
              </ListElement>
            </List>
          </ListWrapper>
        </div>
      </Container>
    ),
    note: () => (
      <NoteSection
        profileProgramMembership={
          ProfileProgramMembership.DIABETES_AND_HEART_DISEASE_PREVENTION
        }
        selfPayd={false}
      />
    )
  };
