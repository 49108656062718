import { AutoForm, AutoFormInputField, Button } from "@9amhealth/shared";
import styled from "@emotion/styled";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserCubit, {
  ProfileAttributesKeys
} from "src/state/UserCubit/UserCubit";
import { toast, tracker, useBloc } from "src/state/state";
import { useBloc as useBlocNext } from "@blac/react";
import InDialog from "../InDialog/InDialog";
import Translate from "../Translate/Translate";
import { z } from "zod";
import { CallbackBloc } from "src/ui/components/CallbackRequest/CallbackBloc";
import formatPhoneNumber from "src/lib/formatPhoneNumber";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { IconIllustrationCallback } from "src/constants/icons";
import FakeButton from "src/ui/components/FakeButton/FakeButton";
import openVcfFile from "src/lib/openVcfFile";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import translate from "src/lib/translate";

const Centered = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 2rem 1rem;
`;

const CallbackRequest: FC<{
  view: "success-page" | "dialog";
}> = ({ view }) => {
  const [
    { status, callbackEnabled, disabledReason },
    { requestCallback, handleRequestCancel }
  ] = useBlocNext(CallbackBloc);
  const [{ profileAttributes }] = useBloc(UserCubit);
  const [showRequestDialog, setShowRequestDialog] = useState(false);
  const navigate = useNavigate();

  const phoneNumber = useMemo(() => {
    const number = profileAttributes?.[ProfileAttributesKeys.phoneNumber];

    if (!number) return undefined;

    try {
      const parsed = parsePhoneNumber(number, "US");

      if (!parsed.isValid()) {
        return undefined;
      }

      return parsed.formatNational();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e: unknown) {
      return undefined;
    }
  }, [profileAttributes]);

  const goToApp = () => {
    navigate("/app/home");
  };

  // useEffect on state change, for tracking success page view
  useEffect(() => {
    if (view === "success-page" && callbackEnabled === true) {
      tracker.track("Callback Request Success Page Presented");
    }
    if (view === "success-page" && callbackEnabled === false) {
      goToApp();
    }
  }, [view, callbackEnabled, disabledReason]);

  useEffect(() => {
    // show dialog if status is requested and view is dialog
    if (status === "requested" && view === "dialog") {
      if (!showRequestDialog) {
        // delay showing the dialog to prevent corruption of the dialog
        setTimeout(() => {
          setShowRequestDialog(true);
        }, 1_000);
      }
    }
  }, [status, showRequestDialog, view]);

  if (callbackEnabled === null) {
    return null;
  }

  const handleSubmit = async (data: { phone: string }) => {
    const unmasked = data.phone.replace(/\D/g, "");
    const formatted = formatPhoneNumber(unmasked);

    if (!formatted) {
      toast.error("invalid_phone_number");
      return;
    }

    if (!phoneNumber || formatted !== phoneNumber) {
      try {
        await UserCubit.setNotificationNumber(formatted);
      } catch (e: unknown) {
        reportErrorSentry(e);

        toast.error("error_generic");
      }
    }

    requestCallback();
    goToApp();
  };

  if (view === "success-page") {
    return (
      <>
        <AutoForm
          initialValue={{
            phone: phoneNumber
          }}
          schema={z.object({
            phone: z.string().refine((value) => {
              if (!value) return false;

              const parsed = formatPhoneNumber(value);

              if (!parsed) return false;

              return isValidPhoneNumber(parsed, "US");
            }, translate("error.phone"))
          })}
          onSubmit={(data) => void handleSubmit(data)}
        >
          <AutoFormInputField
            label="Your phone number"
            name="phone"
            mask={{
              lazy: true,
              mask: "(000) 000-0000"
            }}
          />

          <nine-spacer s="lg"></nine-spacer>

          <Button theme="charcoal" center type="submit">
            <Translate msg="requestCallback.button.request" />
          </Button>

          <nine-spacer s="xs"></nine-spacer>

          <Button
            center
            theme="transparent"
            onPress={() => {
              goToApp();
            }}
          >
            <Translate msg="requestCallback.button.deny" />
          </Button>
        </AutoForm>
      </>
    );
  }

  if (showRequestDialog) {
    return (
      <InDialog
        simple
        height="400px"
        width="366px"
        backdropDismiss={false}
        onWillDismiss={handleRequestCancel}
        alwaysModal
        key="request"
        background="var(--color-white)"
      >
        <Centered>
          <div>
            <IconIllustrationCallback />
            <h5 style={{ fontWeight: 500 }}>
              <Translate msg="requestCallback.popup.title" />
            </h5>
            <p>
              <Translate msg="requestCallback.popup.description" />
            </p>
            <FakeButton onClick={() => openVcfFile()}>
              (202) 932-9958
            </FakeButton>
          </div>
        </Centered>
      </InDialog>
    );
  }

  return null;
};

export default CallbackRequest;
