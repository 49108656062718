import styled from "@emotion/styled";
import { APP_BREAKPOINT } from "src/constants/layout";

const Highlights = styled.div`
  border-radius: 0.5rem;
  border: 1px solid var(--color-gray-lighter);
  padding: calc(var(--space-sm) * 0.75);
  margin: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 0;

  @media (min-width: ${APP_BREAKPOINT}px) {
    padding: calc(var(--space-sm) * 0.875) var(--space-sm);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: ${APP_BREAKPOINT}px) {
      flex-direction: row;
    }

    li {
      width: 100%;
      list-style: none;
      padding: 0;
      font-weight: 500;
      font-size: calc(var(--sm-scale) * 0.75);
      line-height: 140%;
      letter-spacing: -0.02em;
      margin: 0.25rem 0;
      color: var(--color-charcoal-80);

      display: flex;
      align-items: center;
      gap: 0.5rem;

      @media (min-width: ${APP_BREAKPOINT}px) {
        width: 50%;
      }

      &:before {
        content: none;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export default Highlights;
