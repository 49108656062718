import React from "react";
import CallbackRequest from "src/ui/components/CallbackRequest/CallbackRequest";
import Translate from "src/ui/components/Translate/Translate";
import type { SignupSuccessPageProps } from "../../SignupSuccessPage";

const genericFinalSuccessPageProps: SignupSuccessPageProps = {
  title: (
    <>
      <Translate msg="funnel.success.title" />
    </>
  ),
  subtitle: <Translate msg="funnel.success.subtitle" />,
  info: {
    content: <CallbackRequest view="success-page" />
  },
  titleSpacer: false
};

export default genericFinalSuccessPageProps;
