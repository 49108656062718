import { Cubit } from "blac-next";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import { subscriptionState, tracker } from "src/state/state";
import { featureFlags } from "src/lib/featureFlags";
import {
  CareControllerService,
  FeatureResponse,
  SubscriptionDetailsResponse
} from "@9amhealth/openapi";
import { PayerId } from "src/state/SubscriptionCubit/SubscriptionCubit";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { Blac } from "blac-next";
import { AppRemoteConfigCubit } from "src/state/AppRemoteConfigCubit/AppRemoteConfigCubit";

export interface CallbackRequestState {
  callEta?: string;
  status: "prompt" | "requested" | "canceled";
  // null means we haven't checked yet
  callbackEnabled: boolean | null;
  disabledReason?: string;
  callbackRequestedTime?: number;
}

export class CallbackBloc extends Cubit<CallbackRequestState> {
  constructor(runInit = true) {
    super({
      status: "prompt",
      callbackEnabled: null
    });
    if (runInit) {
      this.recoverCachedData();
      void this.checkCallbackEnabled();
    }
  }

  lsKey = "callbackRequest";

  log = (message: string, etc?: unknown): void => {
    if (this.verboseLogging) {
      // eslint-disable-next-line no-console
      console.warn(`[CALLBACK]: ${message}`, etc ?? "");
    }
    addSentryBreadcrumb("callback", message, "info", etc ?? undefined);
  };
  verboseLogging = false;

  private setCachedData = () => {
    StorageController.setItem(this.lsKey, JSON.stringify(this.state));
  };

  recoverCachedData = () => {
    const cached = StorageController.getItem(this.lsKey);
    if (cached) {
      try {
        const data = JSON.parse(cached) as CallbackRequestState;
        // dont cache callbackEnabled
        data.callbackEnabled = null;

        this.emit(data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  checkCallbackEnabled = async () => {
    const [isInRange] = await Promise.all([
      Blac.getBloc(AppRemoteConfigCubit).checkCallbackAvailable(),
      featureFlags.loadBackendFlags(),
      featureFlags.loadDynamicFlags()
    ]);

    let callbackEnabled = isInRange;

    let disabledReason = isInRange ? undefined : "not_in_range";

    // if time is in range, check if feature flag is enabled
    if (isInRange) {
      callbackEnabled =
        featureFlags.getFlag(
          FeatureResponse.feature.ONBOARDING_REQUEST_CALLBACK
        ) && !featureFlags.getFlag("disable_request_callback_onboarding_call");
      if (!callbackEnabled) {
        disabledReason = "feature_disabled";
      }
    }

    // check if user has a cash-pay subscription
    if (callbackEnabled) {
      const cashPaySubscription = subscriptionState.filterAllSubscriptions({
        status: [
          SubscriptionDetailsResponse.status.ACTIVE,
          SubscriptionDetailsResponse.status.IN_REVIEW
        ],
        metadataPayerId: PayerId.CASH_PAY
      });

      if (cashPaySubscription.length > 0) {
        callbackEnabled = false;
        disabledReason = "cash_pay_subscription";
      }
    }

    this.patch({ callbackEnabled, disabledReason });
    this.setCachedData();
  };

  handleRequestCancel = () => {
    if (this.state.status === "requested") {
      tracker.track("Callback Request Call-Now Dialog Closed");
      this.patch({ status: "canceled" });
      this.setCachedData();
    }
  };

  requestCallback = () => {
    if (this.state.status === "requested") {
      return;
    }

    this.patch({
      status: "requested",
      callbackRequestedTime: Date.now()
    });
    this.setCachedData();

    tracker.track("Callback Request Call-Now Requested");

    CareControllerService.triggerOnboardingCallback()
      .then(() => {
        addSentryBreadcrumb("callback", "Trigger Onboarding Callback");
      })
      .catch((e: unknown) => {
        reportErrorSentry(
          new Error("Error triggering onboarding callback", {
            cause: e
          })
        );
      });
  };
}
